@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.sVEnterVisibilityTransition {
	opacity: 1;
	transition: all .25s;
	visibility: visible;
}

.sVExitVisibilityTransition {
	opacity: 0;
	transition: all .25s;
	visibility: hidden;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 20px;
}

::-webkit-scrollbar-thumb {
	background: #dfe0e2;
	border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
	background: #b3b6bc;
}

.calendly-button{
	.calendly-cutom-button{
		font-size:16px;
		padding: 15px 40px;
		display: block;
		margin: 0 auto;
		border: 0;
	}
}

#root .calendly-overlay{
	z-index: 99999999999999;
}