body {
	background-color: #fff;
	color: rgb(18, 52, 77);
	display: flex;
	flex-direction: column;
	font-family: 'Poppins', sans-serif !important;
	font-size: 14px;
	font-weight: 400;
	height: 100%;
	width: 100%;
}

.videoPageLoader {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-name: spin;
		animation-name: spin;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
}

.playerHeaderWrapper {
	width: 100%;
	background-color: rgb(255, 255, 255);
	box-shadow: rgb(81 107 152 / 8%) 0px 2px 4px;
	min-height: 71px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	z-index: 999999;
	// @media (max-height: 500px) {
	// 	display: none;
	// }
	.logoWrapper {
		max-width: 150px;
	}
}

.playerMainContainer {
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	position: relative;
	width: 100%;
	box-shadow: 0 25px 50px -5px rgb(50 50 93 / 45%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 15px -10px rgb(0 0 0 / 25%);
	margin: 0 auto;
	max-height: 540px;
	min-height: 540px;
	padding-top: 0;
	cursor: pointer;
	&.Horizontal {
		max-width: 960px;
	}
	&.Vertical{
		max-width: 400px;
		max-height: 711px;
		min-height: auto;
	}
	.playerContentContainer {
		border-radius: 10px;
		flex: 1 1;
		left: 0;
		overflow: hidden;
		padding-top: 0;
		position: relative;
		top: 0;
		height: 100%;
		width: 100%;
	}

	.interactiveLayoutWrapper {
		display: grid;
		position: relative;
		width: 100%;
		height: 100%;
		inset: 0px;
		overflow: hidden;
		flex-wrap: nowrap;

		video#VideoPlayer {
			border-radius: 10px;
			position: absolute;
			min-height: 100%;
			object-position: 50% 50%;
			object-fit: cover;
			top: 0px;
			left: 0px;
			min-width: 100%;
			background: transparent;
			width: 100%;
			height: 100%;
		}

		#svInnerPlayerWrapper {
			display: grid;
			height: 100%;
			grid-template-rows: 3fr;
			grid-area: 1 / 1 / auto / auto;

			#svOverlayControlsContainer {
				display: flex;
				flex-direction: column;
				-webkit-box-pack: justify;
				justify-content: space-between;
				border-radius: 10px;
				width: 100%;
				height: 100%;
				transition: all 0.3s ease 0s;
				z-index: 10;

				&:hover {
					#svControlsHeader {
						opacity: 1;
					}
				}

				#svControlsHeader {
					height: 100%;
					min-height: 30px;
					display: flex;
					-webkit-box-pack: center;
					justify-content: center;
					z-index: 41;
					transition: all 0.3s ease 0s;
					opacity: 0;

					#svControlsInnerWrapper {
						height: 40px;
						padding: 16px 24px;
						width: 100%;
						display: grid;
						grid-template-columns: auto 1fr auto auto;
						column-gap: 16px;
						-webkit-box-align: center;
						align-items: center;
						z-index: 30;

						&.goBackBtnEnabled.chaptersEnabled.speedOptionEnabled {
							grid-template-columns: auto auto 1fr auto auto auto;
						}

						&.goBackBtnEnabled.speedOptionEnabled:not(.chaptersEnabled),
						&.goBackBtnEnabled.chaptersEnabled:not(.speedOptionEnabled) {
							grid-template-columns: auto auto 1fr auto auto;
						}

						&.goBackBtnEnabled:not(.chaptersEnabled):not(.speedOptionEnabled) {
							grid-template-columns: auto auto 1fr auto;
						}

						&.chaptersEnabled:not(.goBackBtnEnabled):not(.speedOptionEnabled),
						&.speedOptionEnabled:not(.goBackBtnEnabled):not(.chaptersEnabled) {
							grid-template-columns: auto 1fr auto auto;
						}

						&.speedOptionEnabled.chaptersEnabled:not(.goBackBtnEnabled) {
							grid-template-columns: auto 1fr auto auto auto;
						}

						#svVideoChaptersWrapper,
						#goBackPartOptions {
							cursor: pointer;
						}

						#svReloadWrap {
							display: grid;
							-webkit-box-align: center;
							place-items: center;
							border-radius: 500px;
							cursor: pointer;
							filter: initial;
							transition: all 0.2s ease-in-out 0s;
							width: 20px;
							height: 17px;
							cursor: pointer;
						}

						#svVideoSeekBarOuterWrapper {
							width: 100%;
							height: 20px;
							text-align: right;
							margin-top: 12px;

							#svVideoSeekBarInnerWrap {
								position: relative;
								width: 100%;
								height: 6px;
								background-color: rgb(212, 212, 212);
								border-radius: 3px;
								cursor: pointer;

								span#svVideoSeekBarOuterWrapperSpan {
									background-color: #fff;
									position: absolute;
									top: 0;
									left: 0;
									height: 6px;
									width: 0;
									border-radius: 3px;
								}
							}

							#svTimeVideoWrapper {
								color: #fff;
								font-size: 12px;
								line-height: 14px;
								margin-top: 5px;
							}
						}

						#svMuteWrapper {
							display: grid;
							-webkit-box-align: center;
							place-items: center;
							border-radius: 500px;
							cursor: pointer;
							filter: initial;
							transition: all 0.2s ease-in-out 0s;
							cursor: pointer;
						}

						#svVideoSpeedWrapper {
							display: grid;
							-webkit-box-align: center;
							place-items: center;
							cursor: pointer;
							filter: initial;
							transition: all 0.2s ease-in-out 0s;
							padding: 10px;
							background: rgba(255, 255, 255, 0.5);
							color: rgb(255, 255, 255);
							font-weight: 600;
							font-size: 14px;
							// width: 27px;
							border-radius: 8px;
						}
					}
				}
			}
		}

		.sVPlayPauseContainer {
			display: flex;
			position: absolute;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			width: 100%;
			height: 100%;
			bottom: 0px;
			left: 0px;
			border-radius: 10px;
			background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
			transition: all 250ms ease 0s;
			z-index: 10000;

			&.zIndexLow {
				z-index: 1;
			}

			.sVPlayPauseInnerWrapper {
				z-index: 20;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				place-items: center;
				flex-direction: column;
				-webkit-box-align: center;
				cursor: pointer;
				opacity: 1;
				transform: scale(1);
				transition: all 250ms ease 0s;
				width: 100%;

				.sVPlayPauseBtnWrapper {
					transition: all 0.3s ease-in-out 0s;
					visibility: hidden;
					opacity: 0;
					transform: scale(1);
					cursor: pointer;

					&.active {
						opacity: 1;
						visibility: visible;
					}
				}

				.beginTextWrapper {
					position: absolute;
					direction: ltr;
					top: 90px;
					margin-top: 20px;
					width: 100%;
					color: rgb(255, 255, 255);
					font-size: 18px;
					line-height: 21px;
					opacity: 0;
					transform: scale(0.9);
					transition: all 250ms ease 0s;
				}
			}
		}
	}
}

#chapterMainContainer {
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
	background: rgba(0, 0, 0, .6);
	border-radius: 10px;
	display: grid;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1000000;

	#chapterSubMainContainer {
		background: #000;
		border-radius: 0 10px 10px 0;
		display: grid;
		grid-template-columns: auto 1fr;
		height: 100%;
		justify-self: end;
		overflow-y: auto;
		position: relative;

		&::before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 5px;
			background-color: var(--themeBackgroundColor);
			bottom: 0;
		}

		#chapterPickerContainer {
			display: grid;
			grid-template-rows: auto 1fr;
			height: 100%;
			overflow-y: auto;

			svg {
				cursor: pointer;
				justify-self: end;
				margin: 16px;
			}

			#chaptersContainer {
				grid-gap: 24px;
				align-self: center;
				display: grid;
				gap: 24px;
				justify-items: start;
				min-width: 180px;
				padding-bottom: 16px;
				padding-left: 40px;
				padding-right: 40px;

				.chapterListMainContainer {
					cursor: pointer;
					display: grid;
					width: 100%;

					.chapterListSubContainer {
						grid-gap: 10px;
						color: #fff;
						display: grid;
						font-size: 24px;
						font-weight: 300;
						gap: 10px;
						grid-template-columns: auto 1fr;
						justify-items: start;
						justify-self: center;
						width: 100%;

						.chapterActiveMarker {
							background: transparent;
							height: 100%;
							width: 4px;

							&.active {
								background: var(--themeBackgroundColor);
							}
						}

						.chapterTitle {
							word-wrap: break-word;
							justify-self: start;
							max-width: 200px;
							text-align: start;
						}

						.chapterSeprator {
							background: #fff;
							grid-column: 2;
							grid-row: 2;
							height: 2px;
							opacity: .2;
							width: 100%;
						}
					}

					&.noCursor {
						cursor: default;
						pointer-events: none;
					}
				}
			}
		}
	}
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 20px;
}

::-webkit-scrollbar-thumb {
	background: #dfe0e2;
	border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
	background: #b3b6bc;
}

#svVideoControlBack {
	display: flex;
	flex-direction: column;
	opacity: 1;
	transition: all .3s;
	z-index: 40;

	#svVideoAnswerContainer {
		-webkit-box-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		align-items: stretch;
		width: 100%;
		max-width: 800px;
		margin: 0px auto;
		max-height: 300px;
		overflow-y: auto;
		padding-bottom: 28px;
		display: flex;
		flex-flow: row wrap;

		.svAnswerButton {
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.6);
			border: 1px solid rgba(255, 255, 255, 0.3);
			padding: 0px 12px 0px 16px;
			border-radius: 44px;
			box-sizing: border-box;
			color: white;
			transition: opacity 0.3s ease 0s;
			margin: 5px;
			justify-content: normal;
			min-width: 200px;
			max-width: 268px;
			flex: 1 1 0%;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			min-height: 50px;
			height: fit-content;
			min-width: calc(20% + 7em);

			.svChoiceBtnText {
				margin-left: 16px;
				margin-right: 10px;
				line-height: 18px;
				font-size: 16px;
				font-weight: 500;
				text-align: left;
				letter-spacing: 0.02em;
			}

			.svChoiceCharWrapper {
				min-width: 18px;
				min-height: 18px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				background-color: #fff;
				color: #2F3137;
				border-radius: 50%;
				line-height: 18px;
				font-size: 12px;
				font-weight: 600;
				align-items: center;
			}
		}
	}
}

.videoRecordOuterWrap {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	border-radius: 10px;
	background: black;
}

.videoRecordInnerWrap {
	background: transparent;
	display: grid;
	height: 100%;
	overflow: visible;
	width: 100%;
}

video#videoRecorderStream {
	width: 100%;
	height: 100%;
	grid-area: 1 / 1 / auto / auto;
	border-radius: 10px;
	object-fit: cover;
	overflow: hidden;
	transition: opacity 250ms ease 0s;
	transform: rotateY(180deg);
}

.videoTopOptionWrapper {
	display: grid;
	grid-area: 1 / 1 / auto / auto;
	z-index: 3;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.videoTopOptionInnerWrapper {
	display: flex;
	padding: 16px;
	justify-items: center;
	gap: 10px;
	align-self: start;
}

.videoDeviceInputWrapper {
	grid-area: 1 / 1 / auto / auto;
	display: grid;
	grid-template-columns: auto auto 1fr auto auto;
	justify-items: center;
	gap: 10px;
	align-self: start;
}

.videoInputWrapper {
	display: flex;
	flex-direction: column;
	z-index: 4;
	position: relative;
}

.videoInputInnerWrap {
	display: flex;
	-webkit-box-align: center;
	place-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	border-radius: 100%;
	border: none;
	background-color: rgba(9, 10, 11, 0.3);
	transition: all 0.2s ease 0s;
}

.toolTipText {
	display: none;
	position: absolute;
	border-radius: 4px;
	left: 50%;
	transform: translateX(-20%);
	padding: 6px;
	color: white;
	background: black;
	font-size: 14px;
	font-family: sans-serif;
	line-height: 1;
	z-index: 100;
	white-space: nowrap;
	bottom: -36px;
}

.audioInputWrapper .toolTipText {
	transform: translateX(-50%)
}

.audioInputWrapper {
	display: flex;
	flex-direction: column;
	z-index: 4;
	position: relative;
}

.audioInputInnerWrap {
	display: flex;
	-webkit-box-align: center;
	place-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	height: 40px;
	cursor: pointer;
	border: none;
	background-color: rgba(9, 10, 11, 0.3);
	transition: all 0.2s ease 0s;
	width: 100%;
	border-radius: 100px;
	gap: 8px;
	padding: 0px 16px 0 12px;
}

.audioTestContainerWrapper {
	display: flex;
	gap: 8px;
}

.audioTestInnerWrap {
	width: 1px;
	height: 14px;
	background-color: rgb(179, 182, 188);
}

.audioTestInnerWrap.activeVolume {
	background-color: #fff
}

.videoUploadInputWrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: end;
}

.videoInnerUploadLabel {
	display: flex;
	-webkit-box-align: center;
	place-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	border-radius: 100%;
	border: none;
	background-color: rgba(9, 10, 11, 0.3);
	transition: all 0.2s ease 0s;
}

.videoRecordContainer {
	display: grid;
	align-self: end;
	height: 100%;
	justify-items: center;
	grid-area: 1 / 1 / auto / auto;
	overflow: hidden;
	z-index: 0;
}

.audioBottomOptionWrapper,
.videoBottomOptionWrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	right: 0;
	justify-content: center;
	gap: 16px;
	padding: 16px;
	align-items: center;
	z-index: 999;
}

.audioRecordOptionWrapper,
.videoRecordOptionWrapper {
	cursor: pointer;
	display: flex;
	-webkit-box-align: center;
	place-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	border-radius: 500px;
	width: 80px;
	height: 80px;
	transition: all 0.3s ease 0s;
	background: rgb(205 17 47);
	pointer-events: auto;
}

.audioRecordCancelWrapper,
.videoRecordCancelWrapper {
	cursor: pointer;
	display: flex;
	-webkit-box-align: center;
	place-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	border-radius: 500px;
	transition: all 0.3s ease 0s;
	background: rgba(9, 10, 11, 0.6);
	width: 54px;
	height: 54px;
}

.toolTipText:before {
	content: " ";
	left: 20px;
	border-style: solid;
	border-color: transparent transparent black;
	border-image: initial;
	height: 0px;
	width: 0px;
	position: absolute;
	pointer-events: none;
	border-width: 6px;
	margin-left: -6px;
	bottom: 100%;
}

.audioInputWrapper .toolTipText:before {
	left: 50px;
}

.audioInputWrapper:hover .toolTipText,
.videoInputWrapper:hover .toolTipText {
	display: inherit;
}

.toolTipText p {
	margin: 0;
}

.audioInputOptionWrap,
.videoInputOptionWrap {
	background: white;
	position: absolute;
	padding: 4px;
	box-shadow: rgb(232 234 252) 0px 3px 11px 0px, rgba(178, 178, 178, .10) 0px 3px 3px -2px, rgba(154, 154, 154, .10) 0px 1px 8px 0px;
	border-radius: 4px;
	animation: 0.3s ease 0s 1 normal none running fadeIn;
	top: 100%;
	min-width: 280px;
	left: 0;
}

.audioTopOptionInnerWrapper .audioInputOptionWrap {
	left: -120px;
}

.audioInputOptionWrap li.deviceLabel.activeDevice,
.videoInputOptionWrap li.deviceLabel.activeDevice {
	background-color: rgb(243, 245, 255);
}

.audioInputOptionWrap li p,
.videoInputOptionWrap li p {
	max-width: 50ch;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	padding: 6px 0px;
	margin: 5px;
	text-align: left;
}

.audioInputOptionWrap ul,
.videoInputOptionWrap ul {
	padding: 0;
	margin: 0;
}

.audioInputOptionWrap li.deviceLabel,
.videoInputOptionWrap li.deviceLabel {
	background-color: white;
	cursor: pointer;
	transition: all 0.2s ease 0s;
	list-style: none;
	padding: 10px 12px;
}

.audioRecorderCounter,
.videoRecorderCounter {
	z-index: 1000;
	grid-area: 1 / 1 / auto / auto;
	display: grid;
	-webkit-box-align: center;
	place-items: center;
	background: rgba(0, 0, 0, 0.62);
	font-family: sans-serif;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.audioRecorderCounter .counterText,
.videoRecorderCounter .counterText {
	color: rgb(255, 255, 255);
	font-weight: 600;
	font-size: 20em;
	line-height: 1
}

.videoRecordTimerWrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	grid-area: 1 / 1 / auto / auto;
	width: 100%;
	z-index: 2;
	display: grid;
	place-items: center;
	-webkit-box-align: center;
	align-self: start;
}

.videoRecordTimerInner {
	margin: 16px;
	padding: 8px;
	border-radius: 8px;
	width: fit-content;
	color: white;
	background: rgb(205 17 47);
}

.innerOptionWrapper {
	grid-area: 1 / 1 / auto / auto;
	z-index: 100;
	display: flex;
	flex-direction: column;
	gap: 16px;
	place-self: end center;
	margin-bottom: 20px;
	-webkit-box-align: center;
	align-items: center;
	background: rgba(9, 10, 11, 0.5);
	border-radius: 16px;
	padding: 16px;
}

.innerOptionWrap {
	display: flex;
	align-items: center;
	gap: 15px;
	color: #fff;
}

.optionText {
	font-weight: 700;
	line-height: 14px;
	font-size: 14px;
	color: #fff;
}

.optionValueWrapper {
	display: flex;
	align-items: center;
	margin-left: 10px;
	gap: 10px;
}

.noOptionValue,
.yesOptionValue {
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	height: 46px;
	width: 46px;
	cursor: pointer;
	border-radius: 16px;
	background: #fff;
}

.yesOptionValue {
	background: rgb(1, 190, 129);
}

.yesOptionValue path {
	fill: #fff;
}

.noOptionValue {
	background-color: rgb(254, 225, 230);
}

.noOptionValue path {
	fill: rgb(250, 83, 110);
}

.audioRecordMuteUnmuteWrapper,
.videoRecordMuteUnmuteWrapper {
	display: grid;
	grid-area: 1 / 1 / auto / auto;
	place-self: start end;
	place-items: center;
	-webkit-box-align: center;
	height: 40px;
	width: 40px;
	margin: 12px;
	z-index: 10000;
	border-radius: 500px;
	background-color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

.permissionErrorHtml {
	font-size: 18px;
	inset: 0px;
	z-index: 1000000;
	background: black;
	position: absolute;
	padding: 16px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	flex-direction: column;
	color: white;
}

.permissionErrorHtml p {
	margin: 0;
	width: 50%;
}

.audioResultContainer {
	display: none;
}

button.permissionErrorButton {
	font-weight: 400;
	padding: 0px 20px;
	color: white;
	width: fit-content;
	min-width: 200px;
	height: 40px;
	border-radius: 20px;
	cursor: pointer;
	background-color: rgba(10, 10, 10, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.4);
	margin-top: 20px;
}

div#audioRecordOuterWrap {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	border-radius: 9px;
	background: black;
}

.audioRecordInnerWrap,
.audioRecordContainer {
	display: grid;
	height: 100%;
	width: 100%;
}

canvas.audioRecorderStream {
	grid-area: 1 / 1 / auto / auto;
	width: 100%;
	height: 100%;
	border-radius: 8px;
}

.audioTopOptionWrapper {
	display: grid;
	grid-area: 1 / 1 / auto / auto;
	z-index: 3;
	width: 100%;
}

.audioTopOptionInnerWrapper {
	grid-area: 1 / 1 / auto / auto;
	display: grid;
	padding: 16px;
	grid-template-columns: 1fr;
	justify-items: center;
	gap: 10px;
	align-self: start;
}

.audioTopOptionWrapper .audioInputInnerWrap {
	display: flex;
	-webkit-box-align: center;
	place-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	height: 40px;
	cursor: pointer;
	border: none;
	background-color: rgba(9, 10, 11, 0.3);
	transition: all 0.2s ease 0s;
	width: 100%;
	border-radius: 100px;
	gap: 8px;
	padding: 0px 6px;
}

.countDownRecordingLayout {
	z-index: 1000;
	grid-area: 1 / 1 / auto / auto;
	display: grid;
	-webkit-box-align: center;
	place-items: center;
	background: rgba(0, 0, 0, 0.62);
	font-family: sans-serif;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.counterText {
		color: rgb(255, 255, 255);
		font-weight: 600;
		font-size: 330px;
	}
}

.audioTestInnerWrap.activeAudioTest {
	background-color: #fff;
}

.meshLoader {
	z-index: 1000;
	grid-area: 1 / 1 / auto / auto;
	display: grid;
	-webkit-box-align: center;
	place-items: center;
	background: rgba(0, 0, 0, 0.62);
	font-family: sans-serif;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	height: inherit;
	width: inherit;
}

.meshLoader .loaderCircle {
	width: 30px;
	height: 30px;
	position: absolute;
	background: rgb(205, 17, 7);
	border-radius: 50%;
	margin: -15px;
	-webkit-animation: mesh 3s ease-in-out infinite -1.5s;
	animation: mesh 3s ease-in-out infinite -1.5s;
}

.meshLoader>div .loaderCircle:last-child {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.meshLoader>div {
	position: absolute;
	top: 50%;
	left: 50%;
}

.meshLoader>div:last-child {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

@keyframes mesh {
	0% {
		-webkit-transform-origin: 50% -100%;
		transform-origin: 50% -100%;
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	50% {
		-webkit-transform-origin: 50% -100%;
		transform-origin: 50% -100%;
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}

	50.1% {
		-webkit-transform-origin: 50% 200%;
		transform-origin: 50% 200%;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform-origin: 50% 200%;
		transform-origin: 50% 200%;
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}


#contactFormMainContainer {
	color: var(--themePlayerColor);
	background: var(--themePlayerColor);
	border-color: var(--themePlayerColor);
	transition: background-color 350ms linear 0s;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 100000011;
	border-radius: 10px;
}

#contactFormMainContainer #contactFormSubMainContainer {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	overflow: hidden auto;
	background: transparent;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormLeftContainer {
	display: grid;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer {
	display: grid;
	grid-template-rows: auto 1fr auto;
	margin: 16px 16px 16px 0px;
	background: white;
	border-radius: 10px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormLeftContainer #contactFormLeftText {
	place-self: center self-start;
	padding: 20px 10px 20px 44px;
	max-width: 383px;
	grid-area: 1 / 1 / auto / auto;
	text-align: start;
	font-style: normal;
	font-weight: normal;
	font-size: 28px;
	line-height: 150.9%;
	color: var(--themeTextColor);
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formFieldOuterWrapper {
	display: grid;
	place-self: center;
	gap: 16px;
	width: 80%;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formFieldFooter {
	display: grid;
	grid-template-columns: 1fr 1fr;
	place-self: end;
	margin: 20px;
	gap: 8px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .constentForm {
	width: 100%;
	text-align: start;
	-webkit-box-align: center;
	align-items: center;
	display: flex;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField {
	position: relative;
	width: 100%;
	margin-bottom: 15px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .form-control {
	border: 3px solid var(--themePlayerColor);
	padding: 12px 15px;
	color: var(--themePlayerColor);
	margin-left: initial;
	width: 100%;
	font-size: 20px;
	font-weight: 600;
	appearance: none;
	outline: none;
	z-index: 0;
	background-color: transparent;
	position: relative;
	z-index: 2;
	box-shadow: none;
	outline: none;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .form-control.is-invalid {
	border-color: #dc3545;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .form-control.is-invalid+.svFormLabel {
	color: #dc3545
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer label.svFormLabel {
	position: absolute;
	bottom: 15px;
	left: 15px;
	transition: .3s all ease-in;
	z-index: 0;
	margin: 0;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.activeField .svFormLabel,
#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .form-control:focus+.svFormLabel {
	bottom: 47px;
	background-color: #fff;
	padding-left: 5px;
	padding-right: 5px;
	z-index: 3;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .constentForm label.customCheckBox2 {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-right: 10px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .constentForm #svFormConsent {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .constentForm .customCheckBox2 .checkmark {
	position: absolute;
	top: 10px;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: var(--themePlayerColor);
	border-radius: 3px;
	opacity: 0.5;
	transition: .4sec all ease-in
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .constentForm .customCheckBox2 .checkmark:after {
	left: calc(50% - 2.5px);
	top: calc(50% - 5.8px);
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2.5px 2.5px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	content: "";
	position: absolute;
	display: none;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .constentForm .customCheckBox2 input:checked~.checkmark:after {
	display: block;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .constentForm .customCheckBox2 input:checked~.checkmark {
	background-color: var(--themePlayerColor);
	opacity: 1;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formFieldFooter .formFooterActionStep {
	display: grid;
	-webkit-box-align: center;
	place-items: center;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formFieldFooter button.nextStepButton {
	appearance: none;
	text-align: center;
	height: 42px;
	width: min-content;
	min-width: 90px;
	padding: 10px;
	outline: none;
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	cursor: pointer;
	opacity: 1;
	grid-area: 1 / 1 / auto / auto;
	color: var(--themeTextColor);
	background: var(--themePlayerColor);
	border-color: var(--themePlayerColor);
	transition: background-color 0s ease 0s, border-bottom-color 0s ease 0s, border-top-color 0s ease 0s, border-left-color 0s ease 0s, border-right-color 0s ease 0s, all 350ms linear 0s;
	border-radius: 6px;
	box-shadow: none;
	border-style: solid;
	display: flex;
	align-items: center;
	justify-content: center;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .form-error .form-control {
	border-color: #dc3545;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.phoneInputWrapper.invalidField .invalid-feedback,
#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .form-error .formError,
#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .invalid-feedback {
	position: absolute;
	color: #dc3545;
	font-size: 12px;
	left: 0;
	display: block;
	top: 100%;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .form-error label.svFormLabel {
	color: #dc3545;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formFieldFooter button.disableBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: not-allowed;
	pointer-events: none;
	opacity: .6;
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 80px;
	transform: scale(0.4);
}

.lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}

.lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #fff;
	margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
}

.lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
}

.lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
}

.lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
}

.lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
}

.lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
}

.lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
}

.lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
}

@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formFieldFooter button#formBackButton {
	appearance: none;
	text-align: center;
	height: 42px;
	width: min-content;
	min-width: 90px;
	padding: 10px;
	outline: none;
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	cursor: pointer;
	opacity: 1;
	grid-area: 1 / 1 / auto / auto;
	color: rgb(136, 136, 136);
	background: transparent;
	border-color: transparent;
	transition: background-color 0s ease 0s, border-bottom-color 0s ease 0s, border-top-color 0s ease 0s, border-left-color 0s ease 0s, border-right-color 0s ease 0s, all 350ms linear 0s;
	border-radius: 6px;
	box-shadow: none;
	border-style: solid;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.phoneWrapper .form-control {
	padding-left: 45px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.phoneWrapper label.svFormLabel {
	left: 55px;
	bottom: 18px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.activeField.phoneWrapper label.svFormLabel {
	bottom: 47px;
	left: 15px;
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
	z-index: 45;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.selectWrapper .svFormSelectLabel {
	position: absolute;
	top: -32px;
	font-size: 18px;
	font-weight: 600;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.selectWrapper {
	margin-top: 45px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.selectWrapper:after {
	content: "";
	width: 20px;
	height: 10px;
	background-color: var(--themePlayerColor);
	-webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
	clip-path: polygon(100% 0%, 0 0%, 50% 100%);
	position: absolute;
	top: calc(50% - 5px);
	right: 12px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.selectWrapper .form-control {
	padding-right: 33px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1 {
	display: block;
	position: relative;
	padding-left: 27px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1>input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	display: none;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1 span.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border-radius: 50%;
	border: 1px solid var(--themePlayerColor);
	opacity: 0.3;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1:hover input~.checkmark {
	background-color: var(--themePlayerColor);
	transition: 0.5sec all ease-in;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1 input:checked~.checkmark {
	background-color: var(--themePlayerColor);
	opacity: 1;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1 input:checked~span.checkmark:after {
	border-color: var(--themeTextColor);
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1.customCheckBox3 {
	font-size: 17px;
	padding-left: 30px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1.customCheckBox3 span.checkmark:after {
	width: 5px;
	height: 8px;
	top: 3px;
	left: 6px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1.customCheckBox3 span.checkmark {
	width: 20px;
	height: 20px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper {
	display: grid;
	place-items: self-start;
	gap: 12px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .svFormRadioLabel {
	font-size: 18px;
	font-weight: 600;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .optionGroupInnerWrap {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin-top: 10px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2 {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-right: 10px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	display: none;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2 .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: var(--themePlayerColor);
	border-radius: 3px;
	opacity: 0.4
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2:hover input~.checkmark {
	background-color: var(--themePlayerColor);
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2 input:checked~.checkmark {
	background-color: var(--themePlayerColor);
	opacity: 1;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2 .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2:hover input~.checkmark:after,
#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2 input:checked~.checkmark:after {
	display: block;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .optionGroupWrapper .customCheckBox2 .checkmark:after {
	left: calc( 50% - 2.5px );
	top: calc( 50% - 5.8px );
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2.5px 2.5px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.textareaWrapper label.svFormLabel {
	bottom: inherit;
	top: 15px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.textareaWrapper.activeField label.svFormLabel,
#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.textareaWrapper .form-control:focus+label.svFormLabel {
	top: -8px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.textareaWrapper .form-control {
	font-size: 15px;
	font-weight: 600;
}

#endScreenMainContainer {
	z-index: 50;
	transition: all 0.3s ease 0s;
	background-color: black;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	border-radius: 10px;
	justify-content: center;
}

#endScreenMainContainer #endScreenSubMainContainer {
	display: grid;
	justify-items: center;
	align-self: center;
	padding: 0px 20px;
}

#endScreenMainContainer #endScreenSubMainContainer #endScreenTopTextContainer {
	display: flex;
	flex-direction: row;
	gap: 8px;
	-webkit-box-align: center;
	align-items: center;
}

#endScreenMainContainer #endScreenSubMainContainer #endScreenTopTextContainer h5 {
	display: inline;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	color: white;
	font-size: 25px;
	max-width: 600px;
	white-space: pre-wrap;
	margin: 0;
}

#endScreenMainContainer #endScreenSubMainContainer #endScreenButtonContainer {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 20px;
	position: relative;
}

#endScreenMainContainer #endScreenSubMainContainer #endScreenButtonContainer.singleOption {
	grid-template-columns: repeat(1, 1fr);
}

#endScreenMainContainer #endScreenSubMainContainer #endScreenButtonContainer #endScreenBackButtonWrapper {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	margin: 8px;
}

#endScreenMainContainer #endScreenSubMainContainer #endScreenButtonContainer #endScreenRestartButton {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	margin: 8px;
}

#endScreenMainContainer #endScreenSubMainContainer #endScreenButtonContainer #endScreenBackButtonWrapper span,
#endScreenMainContainer #endScreenSubMainContainer #endScreenButtonContainer #endScreenRestartButton span {
	margin-top: 6px;
	color: white;
	text-align: center;
}

.audioRecordMuteUnmuteWrapper+.innerOptionWrapper {
	place-self: start center;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.formField.phoneInputWrapper .PhoneInput {
	position: relative;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.formField.phoneInputWrapper .PhoneInput .PhoneInputCountry {
	position: absolute;
	top: 22px;
	left: 10px;
	z-index: 999;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.formField.phoneInputWrapper .PhoneInput .PhoneInputInput {
	border: 3px solid var(--themePlayerColor);
	padding: 12px 15px;
	color: var(--themePlayerColor);
	margin-left: initial;
	width: 100%;
	font-size: 20px;
	font-weight: 600;
	appearance: none;
	outline: none;
	z-index: 0;
	background-color: transparent;
	position: relative;
	z-index: 2;
	box-shadow: none;
	outline: none;
	padding-left: 47px;
	border-radius: 0.375rem;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .phoneInputWrapper label.svFormLabel {
	left: 50px;
	bottom: 18px;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.phoneInputWrapper.activeField .svFormLabel,
#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .phoneInputWrapper .PhoneInput--focus+label.svFormLabel {
	bottom: 47px;
	left: 30px;
	background-color: #fff;
	padding-left: 5px;
	padding-right: 5px;
	z-index: 3;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .phoneInputWrapper .PhoneInputCountryIcon--border {
	background-color: transparent;
	box-shadow: none;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.phoneInputWrapper.invalidField .PhoneInput .PhoneInputInput {
	border-color: #dc3545;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .formField.phoneInputWrapper.invalidField .svFormLabel {
	color: #dc3545;
}

#contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer .customCheckBox1 span.checkmark:after {
	content: "";
	position: absolute;
	display: block;
	left: calc(50% - 2.5px);
	top: calc(50% - 5.8px);
	width: 5px;
	height: 10px;
	border: solid var(--themeTextColor);
	border-width: 0 2.5px 2.5px 0;
	transform: rotate(45deg);
}

.appContainer{
	display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
	z-index: -1;
}

.tab-content{
	height: 100%;
}

div#svPlayerLoaderWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
	#svPlayerLoaderInnerWrap {
		position: absolute;
		inset: 0px;
		display: grid;
		place-content: center;
		svg{
			-webkit-animation-duration: 1s;
			animation-duration: 1s;
			-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
			-webkit-animation-name: spin;
			animation-name: spin;
			-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
		}
	}
}

.svExitVisibiltyTransition {
	opacity: 0 !important;
	transition: all .25s !important;
	visibility: hidden !important;
}
.svEntryVisibiltyTransition {
	opacity: 1 !important;
	transition: all .25s !important;
	visibility: visible !important;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.playerMainContainer.Vertical #contactFormMainContainer #contactFormSubMainContainer{
	grid-template-columns: 1fr;
}

.playerMainContainer.Vertical #contactFormMainContainer #contactFormSubMainContainer #contactFormRightContainer{margin: 16px;}
.playerMainContainer.Vertical #contactFormMainContainer #contactFormSubMainContainer #contactFormLeftContainer #contactFormLeftText{padding:20px;}

.playerTabWrapper{
	display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    // position: absolute;
    right: 0;
}

.nav-wrapper {
	.tab-pane {
		.card-header {
			padding: 0;
		}
	}

	.nav-fill .nav-item {
		flex: none;
	}

	.nav-pills .nav-link.active,
	.nav-pills .show>.nav-link,
	.nav-pills .nav-link {
		border-radius: 60px;
		cursor: pointer;
	}
}

.nav-pills .nav-link {
    padding: 0.75rem 1rem;
    color: #5e72e4;
    font-weight: 500;
    font-size: 0.875rem;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgb(0, 0, 0, .08);
    background-color: #fff;
    transition: all 0.15s ease;
}

.nav-pills .nav-item:not(:last-child) {
    padding-right: 1rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #5e72e4;
}

.chatPanel{
	min-height: 66vh;
	.messageArea{
		height: 100%;
		max-height: 54vh;
		min-height: 54vh;
		overflow-y: auto;
		padding-bottom: 2vh;
		margin-bottom: 3vh;
		padding-top: 2vh;
		border-bottom: 1px solid #e9ecef !important;
		padding-left: 15px;
		padding-right: 15px;
		.chatBubble{
			padding: 10px 14px;
			background: rgba(218, 236, 255, 0.2784313725);
			color: #12344d;
			margin: 10px 30px;
			position: relative;
			-webkit-animation: fadeIn 1s ease-in;
			animation: fadeIn 1s ease-in;
			font-size: 14px;
			display: inline-block;
			border-radius: 15px 15px 15px 0px;
			margin-bottom: 2px;
			border: 1px solid #daecff;
			&.chatbubble--right{
				color: #12344d;
				background: #f1f3f4;
				border-radius: 15px 15px 0px 15px;
				text-align: left;
				word-break: break-word;
				border-color: #f1f3f4;
			}
			.formDetail{
				display: flex;
    			align-items: center;
			}
			.videoResponse{
				position: relative;
				cursor: pointer;
				border: 1px solid transparent;
				max-width: 400px;
				img{
					width: 100%;
				}
				.play-icon {
					position: absolute;
					top: calc(50% - 18px);
					left: calc(50% - 20px);
					color: white;
					.material-icons {
						font-size: 38px;
					}
				}
			}
		}
		.time{
			color: grey !important;
			background: transparent !important;
			padding: 0 !important;
			margin: 5px 30px;
			font-size: 80%;
			font-weight: 400;
		}
	}
	.typeArea{
		padding-bottom: 2vh;
    	padding-top: 1vh;
		.chatBoxTray{
			.replyBar{
				display: flex;
				align-items: center;
				position: relative;
				padding: 0 20px;
				button{
					background: none;
					border: none;
					box-shadow: none;
					padding: 0;
					&:not(:last-child){
						margin-right: 0.5rem;
					}
					.material-icons-outlined,
					.material-icons{
						color: rgba(64, 63, 88, 0.5411764706);
						font-size: 24px;
						vertical-align: middle;
					}
					&.sendBtn:not(disabled){
						.material-icons{
							color: #5e72e4;
						}
					}
				}
				grammarly-editor-plugin {
					width: 100%;
					padding: 0 10px 0 5px;
					textarea{
						padding: 10px 15px;
						width: 100%;
						border: 2px solid rgb(228, 228, 228) !important;
						overflow: visible;
						border-radius: 30px;
						text-align: start;
						box-sizing: border-box;
						resize: none;
						height: 46px;
						line-height: 20px;
					}
					._1nwc2id0{
						display: none;
					}
				}
			}
		}
	}
}

.AudioRecordModalWrap,
.videoRecordModalWrap{
	width: 100%;
	height: 450px;
}